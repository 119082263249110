<template>
  <div class="App-ydisk">
    <a href="https://hpace.ru" target="_blank">
      <img alt="Vue logo" src="https://card.hpace.dev/favicon.ico">
    </a>
    <h1>Получите бесплатно файлы с папки Яндекс.Диска</h1>
    <form @submit.prevent="getFiles">
      <input class="App-ydisk__input" v-model="public_key" required type="text" placeholder="Ссылка на публичную папку">
      <button type="submit">Получить файлы с папки</button>
      <p><small>*мы не храним у себя ваши токены и публичные ссылки. Всё конфиденциально. Разработано в <a href="https://hpace.ru">HPACE.ru</a></small></p>
    </form>

    <div class="App-ydisk__gallery" v-if="data">
      <h2>Папка "<a target="_blank" :href="data.public_url">{{ data.name}}</a>"</h2>

      <div class="App-ydisk__items">
        <div v-for="(item, index) in data._embedded.items" :key="index">
          <a v-if="item.type != 'dir'" target="_blank" :href="item.preview"><img width="200" :src="item.preview" /></a>
          <b v-else>У вас в папке есть директория. Мы не можем её прочесть в рамках одной ссылки.</b>

          <p>Файл/директория: {{ item.name}}</p>

          <div v-if="item.type != 'dir'">
            <p><b>Размеры:</b></p>
            <p>
              <a class="App-ydisk__link" :href="size.url" :title="size.url" @click.prevent="copyTextToClipboard(size.url)" v-for="(size, indexSize) in item.sizes" :key="indexSize">
                {{ size.name }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      public_key: '',
      token: '',
      data: null
    }
  },

  methods: {
    saveLocal () {
      localStorage.setItem('public_key', this.public_key)
      localStorage.setItem('token', this.token)
    },

    loadLocal () {
      let public_key = localStorage.getItem('public_key'),
        token = localStorage.getItem('token');

      if(public_key && token) {
        this.public_key = public_key
        this.token = token
      }
    }, 
    
    async copyTextToClipboard (text) {
      try {
          await navigator.clipboard.writeText(text)

          alert('Ссылка на файл успешно скопирована')
      } catch (error) {
          console.error('Failed to copy text:', error)
      }
    },

    async getFiles () {
      let link = `https://cloud-api.yandex.net/v1/disk/public/resources?public_key=${this.public_key}`

      console.log(this.token)

      let data = await fetch(link, { headers: { 'Authorization': `OAuth ${this.token}` } }).then(r => r.json())

      if(data.message) {
        console.log(data)
        alert(data.message)
      } else {
        this.data = data
        this.saveLocal()
      }

      
    },
  },
  
  mounted () {
    this.loadLocal()
  }
}
</script>

<style>
.App-ydisk__gallery {
  width: 100%;
}

.App-ydisk__link {
  text-decoration: none;
  padding: 2px;
  margin: 2px;
  font-size: 12px;
  background: #e9e9e9;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
}

.App-ydisk__items > div {
  margin-bottom: 15px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}

.App-ydisk__items {
  display: flex;
  flex-wrap: wrap;
}

.App-ydisk {
  margin: 0 auto;
  max-width: 960px;
}
.App-ydisk > img {
  margin-bottom: 25px;
}

.App-ydisk form button {
  padding: 10px;
  color: #fff;
  border: none;
  background: #4053ff;
  color: white;
  border-radius: 5px;
}

.App-ydisk__input {
  width: 45%;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  padding: 10px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
